.Main {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.Catagory {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  transition: all 500ms;
  transition-delay: 500ms;
  color: #D8DBE2;
}
.CatagoryImage {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.CatagoryContent {
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  font-family: "Helvatica", Helvatica, sans-serif;
  font-size: 20px;
  text-align: left;
}
.CatagoryTitle {
  width: 80vw;
  font-size: 80px;
  font-family: "Courier New", Courier, monospace;
  float: right;
  text-align: right;
  font-size: 60px;
  margin-top: 10px;
  margin-right: 10px;
}
.CatagoryText {
  width: 80vw;
  text-align: justify;
  overflow: auto;
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 8vw;
  background-color: #1B1B1E;
  padding: 2vw;
  border-radius: 10px;
}
.ImageNetwork {
  height: 50vh;
  overflow: auto;
}
.NetworkedPicture {
  border-radius: 30px;
  border-width: 5px;
  border-color: #1B1B1E;
  border-style: solid;
}
.circleButton {
  border-radius: 50%;
  border: none;
  margin: 10px;
  transition: inherit;
  cursor: pointer;
  font-size: 2vmax;
  position: absolute;
}
.circleButtonExploded {
  border: none;
  transition: inherit;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vh;
  height: 100vh;
}
.circleButtonSpan {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #D8DBE2;
  font-family: "Courier New", Courier, monospace;
  transition: inherit;
}
.dropDown {
  transition: all 500ms linear;
  padding: 10px;
  border-color: #D8DBE2;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  margin: 5px;
}
.dropDownTitle {
  transition: all 500ms linear;
  text-align: center;
}
.dropDownContent {
  transition: all 500ms linear;
}
